<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        
        <b-card no-body class="mb-0 mt-1">

            <div class="m-2"> 
                <b-tabs pills>                    
                    <b-tab active title="Permisos">
                        <b-card>
                            <b-card-title>
                               <h3 class="h6">
                                    Configura los permisos asignados a cada rol de usuario, incluyendo accesos y funciones específicas por nivel jerárquico.
                                </h3>   
                            </b-card-title>

                            <b-row>                    
                                <b-col cols="12" md="4" class="align-items-center justify-content-start mb-1 mb-md-0">
                                    <label for="select-rol">Roles de Usuario</label>
                                    <v-select
                                        id="select-rol"
                                        class="select-size-sm"
                                        v-model="role_id"
                                        :options="roles"
                                        :reduce="option => option.id"
                                        label="name"
                                        placeholder="Selecciona una opción"
                                        :clearable="false"
                                        :searchable="true"
                                        @input="selectRole"
                                    />
                                </b-col>

                                <b-col v-if="canAddPermission" cols="12" md="4" class="mt-2">
                                    <b-button size="sm" @click="isActiveModal= true">Agregar</b-button>
                                </b-col>
                            </b-row>
                            <hr>
                           
                            <menu-tree :items="permissions" :role="role_id"/>                            

                            <!-- <b-row>
                                <b-col cols="12" md="4" v-for="(items, index) in permissions" :key="index">
                                    <b-card-actions :ref="index" :title="index" action-collapse :collapsed="false">
                                        <b-table
                                            striped
                                            responsive
                                            :items="items"
                                            class="mb-0"
                                            :fields="columns"
                                            small
                                            :sticky-header="true"
                                            :no-border-collapse="true"
                                            :busy.sync="loading">
                                        
                                            <template #cell(nombre)="data">
                                                <div :style="{ paddingLeft: `${data.item.level * 20}px` }">
                                                    {{ data.item.nombre }}
                                                    <br>
                                                    <small class="help-block">{{ data.item.descripcion }}</small>
                                                </div>
                                            </template>
                                            <template #cell(actions)="data">
                                                <b-form-checkbox :disabled="!role_id" v-model="data.item.enabled" @change="changePermission(data.item)"/>
                                            </template>
                                        </b-table>                                  
                                    </b-card-actions>
                                </b-col>  
                            </b-row> -->
                        </b-card>
                    </b-tab>

                    <b-tab title="Conexiones Por Usuario" class="mt-3">
                        <h3 class="h6">Para poder configurar un permisos debe seleccionar un usuario</h3>
                        <b-row class="mt-2">
                            <b-col cols="12" md="4">
                                <b-form-group label="Usuario" label-for="select-user">
                                    <v-select
                                        id="select-user"
                                        v-model="user_id"
                                        :options="users"
                                        :reduce="option => option.id"
                                        label="fullName"
                                        placeholder="Selecciona una opción"
                                        :clearable="false"
                                        :searchable="true"
                                        @input="selectUser"
                                        class="select-size-sm"
                                    >
                                        <template #option="{ userName, fullName, roleName }">
                                            <span class="ml-50 align-middle">{{ fullName }}</span><br>
                                            <small class="ml-50 align-middle"><feather-icon icon="UserIcon" size="16" class="align-text-top" /> {{ userName }}</small><br>
                                            <small class="ml-50 align-middle"><feather-icon icon="ShieldIcon" size="16" class="align-text-top" /> {{ roleName }}</small><br>
                                        </template>

                                        <template #selected-option="{ fullName, roleName }">                            
                                            <span class="ml-50 align-middle">{{ fullName }} ({{ roleName }})</span>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="user_id" cols="12" md="4">
                                <b-form-group label="Conexiones" label-for="connection_id">
                                    <v-select                                    
                                        id="connection_id"
                                        v-model="connection_id"
                                        :options="connections"
                                        :reduce="option => option.idEmpresa"
                                        label="nombre"
                                        placeholder="Selecciona una opción"
                                        :clearable="false"
                                        :searchable="false"
                                        @input="selectConnection"
                                        class="select-size-sm"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!--  -->
                        <b-row class="mt-2">

                            <b-col cols="12" md="4">
                                <b-table
                                    striped
                                    responsive
                                    :items="branches"
                                    class="mb-0"
                                    :fields="[                                            
                                        { key: 'nombre', label: 'Sucursal' },
                                        { key: 'actions', label: '' },
                                    ]"
                                    small
                                    :busy.sync="loading"
                                    >
                                    
                                    <template #cell(nombre)="data">
                                        {{ data.item.nombre }}
                                    </template>
                                    <template #cell(actions)="data">
                                        <b-form-checkbox v-model="data.item.enabled"/>
                                    </template>
                                </b-table>   
                                
                                
                                <div v-if="user_id && connection_id" class="text-right mt-2">
                                    <b-button size="sm" @click="updateBranchPermissions" :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                        class="mr-2">
                                        Guardar
                                    </b-button>
                                    <b-button size="sm" @click="selectConnection" :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                                        variant="outline-secondary">
                                        Cancelar
                                    </b-button>
                                </div>                                
                            </b-col>                           
                        </b-row>
                    </b-tab> 
                    
                    <b-tab title="Almacenes Por Usuario" class="mt-3">
                        <b-row>
                            <b-col cols="12" md="8">
                                <b-card no-body>
                                    <h3 class="h6">Para poder configurar un almacén debe seleccionar un usuario</h3>

                                    <b-row class="mt-2">
                                        <b-col cols="12" md="4">
                                            <b-form-group label="Usuario" label-for="select-user-warehouses">
                                                <v-select
                                                    id="select-user-warehouses"
                                                    v-model="formWareHouses.user_id"
                                                    :options="users"
                                                    :reduce="option => option.id"
                                                    label="fullName"
                                                    placeholder="Selecciona una opción"
                                                    :clearable="false"
                                                    :searchable="true"
                                                    @input="selectUser"
                                                    class="select-size-sm"
                                                >
                                                    <template #option="{ userName, fullName, roleName }">
                                                        <span class="ml-50 align-middle">{{ fullName }}</span><br>
                                                        <small class="ml-50 align-middle"><feather-icon icon="UserIcon" size="16" class="align-text-top" /> {{ userName }}</small><br>
                                                        <small class="ml-50 align-middle"><feather-icon icon="ShieldIcon" size="16" class="align-text-top" /> {{ roleName }}</small><br>
                                                    </template>

                                                    <template #selected-option="{ fullName, roleName }">                            
                                                        <span class="ml-50 align-middle">{{ fullName }} ({{ roleName }})</span>
                                                    </template>
                                                </v-select>
                                            </b-form-group>
                                        </b-col>

                                        <b-col v-if="formWareHouses.user_id" cols="12" md="4">
                                            <b-form-group label="Conexiones" label-for="connection_id_warehouses">
                                                <v-select                                    
                                                    id="connection_id_warehouses"
                                                    v-model="formWareHouses.connection_id"
                                                    :options="connections"
                                                    :reduce="option => option.idEmpresa"
                                                    label="nombre"
                                                    placeholder="Selecciona una opción"
                                                    :clearable="false"
                                                    :searchable="false"
                                                    @input="selectConnectionWarehouses"
                                                    class="select-size-sm"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="formWareHouses.user_id && formWareHouses.connection_id">

                                        <b-col cols="12" md="8">
                                            <b-table
                                                striped
                                                responsive
                                                :items="wareHouses"
                                                class="mb-0"
                                                :fields="[                                            
                                                    { key: 'nombre', label: 'Almacén' },
                                                    { key: 'actions', label: '' },
                                                ]"
                                                small
                                                :busy.sync="loading"
                                                >
                                                
                                                <template #cell(nombre)="data">
                                                    {{ data.item.nombre }}
                                                </template>
                                                <template #cell(actions)="data">
                                                    <b-form-checkbox v-model="data.item.enabled" @change="handleCheckboxChange(data.item)"/>
                                                </template>
                                            </b-table>   
                                            
                                            
                                            <div class="text-right mt-2">
                                                <b-button size="sm" @click="updateWareHousePermissions" :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                                    class="mr-2">
                                                    Guardar
                                                </b-button>
                                                <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                                                    variant="outline-secondary">
                                                    Cancelar
                                                </b-button>
                                            </div>                                
                                        </b-col>
                                         

                                        <!-- <b-col cols="6">
                                            <h4 class="h6 text-left font-weight-bold mb-2">Puede arrastrar un almacén a la sección de permisos</h4>
                                            <draggable :list="wareHouses" tag="ul" group="wareHouses" class="list-group list-group-flush cursor-move">
                                                <b-list-group-item v-for="(item, index) in wareHouses" :key="index" tag="li">
                                                    <div class="d-flex">
                                                        <b-avatar :text="avatarText2(item.nombre)" />
                                                        <div class="ml-25">
                                                            <b-card-text class="mb-0 font-weight-bold">
                                                                {{ item.nombre }}
                                                            </b-card-text>
                                                        </div>
                                                    </div>
                                                </b-list-group-item>
                                            </draggable> 
                                        </b-col>     
                                        
                                        <b-col md="6" class="mt-sm-2 mt-md-0">
                                            <h4 class="h6 text-center font-weight-bold mb-2">Permisos de almacén Configurado</h4>
                                            <draggable :list="formWareHouses.newWarehouses" tag="ul" group="wareHouses" class="list-group list-group-flush cursor-move"
                                                @change="updateWareHouses"
                                                @start="start"
                                                @end="end"
                                                :move="move">
                                                <b-list-group-item v-for="(item, index) in formWareHouses.newWarehouses" :key="index" tag="li">
                                                    <div class="d-flex">
                                                        <b-avatar :text="avatarText2(item.nombre)" />
                                                        <div class="ml-25">
                                                            <b-card-text class="mb-0 font-weight-bold">
                                                                {{ item.nombre }}
                                                            </b-card-text>
                                                        </div>
                                                    </div>
                                                </b-list-group-item>
                                            </draggable>   
                                        </b-col> -->
                                    </b-row>
                                </b-card>
                            </b-col>                           
                        </b-row>                        
                    </b-tab>
                </b-tabs>
            </div>            
        </b-card>       

        <div v-if="checkedPermissions.length > 0" class="buy-now">
            <b-button :disabled="loading" @click="updatePermissions" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
                Actualizar Permisos
            </b-button>
        </div>

        <!-- Modal Add Permissions -->
        <b-modal ref="infoModalRef" id="modalCreatePermission" title="Agregar Permiso" no-close-on-backdrop hide-footer
            @cancel="isActiveModal = false" @hidden="closeModal" :visible="isActiveModal">

            <b-form class="p-2" ref="formRef" @submit.prevent="onStorePermission" @reset.prevent="resetForm">
                <b-row>
                    <b-col cols="12" md="12">
                        <b-form-group label="Tipo Menu" label-for="type">
                            <v-select
                                id="type"
                                v-model="permission.type"
                                :options="[
                                    { id: 'header1', name: 'Encabezado' },
                                    { id: 'header', name: 'Hijo' },
                                    { id: 'route', name: 'Especial' },
                                ]"
                                :reduce="option => option.id"
                                label="name"
                                placeholder="Selecciona una opción"
                                :clearable="false"
                                :searchable="false"
                                class="select-size-sm"
                                @input="selectPermissionType"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col v-if="permission.type == 'header' || permission.type == 'route'" cols="12" md="12">
                        <b-form-group label="Menu" label-for="parent">
                            <v-select
                                id="parent"
                                v-model="permission.fatherId"
                                :options="menus.filter(item => item.category === permission.type)"
                                :reduce="option => option.id"
                                label="name"
                                placeholder="Selecciona una opción"
                                :clearable="false"
                                :searchable="true"
                                class="select-size-sm"
                            >
                                <template #option="{ name, icon }">
                                    <feather-icon :icon="icon" size="16" class="mr-1" />
                                    <span class="ml-50 align-middle">{{ name }}</span>
                                </template>
                            </v-select>

                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="12">
                        <b-form-group label="Nombre" label-for="name">
                            <b-form-input size="sm" id="name" v-model="permission.name" trim
                                placeholder="Insertar Nombre" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="12">
                        <b-form-group label="Descripción" label-for="description">
                            <b-form-input size="sm" id="description" v-model="permission.description" trim
                                placeholder="Insertar Descripción" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <div class="text-right mt-2">
                    <b-button :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                        variant="outline-secondary" @click="closeModal">
                        Cancelar
                    </b-button>

                    <b-button :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit">
                        Guardar
                    </b-button>
                </div>
            </b-form>
        </b-modal>
    </b-overlay>
</template>

<script>
import _ from "lodash";
import {
    BCardTitle,
    BCardSubTitle,
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BFormInput,
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BInputGroup,
    BCardText,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BSpinner,BFormCheckbox,
    BListGroup, BListGroupItem,
    BTabs,
    BTab,
    BFormGroup,
    BForm,
    BCollapse,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import draggable from 'vuedraggable'
import "animate.css";
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import navMenuItems from '@/navigation/horizontal'
import MenuTree from '@/@core/components/MenuTree.vue';
import store from "@/store";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import RolesProvider from "@/providers/Roles"
const RolesResource = new RolesProvider()

import PermissionProvider from "@/providers/Permissions";
const PermissionResource = new PermissionProvider();

import UsersProvider from "@/providers/Users";
const UsersResource = new UsersProvider()

import ConnectionsProvider from "@/providers/Connections"
const ConnectionsResource = new ConnectionsProvider()

import BranchesProvider from "@/providers/Branches"
const BranchesResouce = new BranchesProvider()

import WareHousesProvider from "@/providers/WareHouses"
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

const WareHousesResouce = new WareHousesProvider()

export default {
    components: {
        BCardTitle,
        BCardSubTitle,
        BCardGroup,
        BCollapse,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BInputGroupPrepend, 
        BInputGroupAppend, 
        BInputGroup,
        BCardText,
        BForm,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BSpinner,
        BOverlay,
        vSelect,
        BFormCheckbox,
        BListGroup, 
        BListGroupItem,
        BCardActions,
        BTabs,
        BTab,
        BFormGroup,
        draggable,

        //   
        Prism,  
        VuePerfectScrollbar,
        AppCollapse,
        AppCollapseItem,
        FeatherIcon, 
        MenuTree,  
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            loading: false,
            permissions: [],
            permission: {
                name: '',
                type: '',                
                order: null,
                group: '',
                description: '',
                special: false,
                fatherId: null,
            },
            rolePermissions: [],
            roles: [],
            role_id: '',
            perPageOptions: [10, 25, 50, 100],
            perPage: 500,
            currentPage: 1,
            totalPermissions: 0,
            columns: [
                {
                    key: "nombre",
                    label: "Permiso",
                },
                {
                    key: "actions",
                    label: '',
                },                
            ],
            sortBy: "nombre",
            sortDesc: false,
            searchQuery: null,
            from: 0,
            to: 0,
            checkedPermissions: [],
            users: [],
            user_id: null,
            connection_id: null,
            company_id: null,
            connections: [],
            branches: [],
            newBranches: [],
            isActiveModal: false,
            wareHouses: [],
            formWareHouses: {
                user_id: null,
                connection_id: null,
                newWarehouses: [],
            },
            collapsed: [],
            permiso: {
                nombre: '',
                tipo: '',
                padreId: '',
            },
            menus: [],
        };
    },
    computed: {
        isAdmin () {
            return store.getters['auth/getRole'] === 'Administrador' || false
        },
        canAddPermission() {
            return store.getters['auth/getUser'].id === store.getters['auth/getUserIdAdmin']
        },
        hasChanges() {
            return this.getEnabledMenus(this.permissions).length > 0; // Habilitar si hay menús habilitados
        },
    },    
    created() {

        // console.error('created')
        // console.log(store.getters['auth/getConnections'])
        // this.connections = store.getters['auth/getConnections']
    },
    async mounted() {
        await this.getConnections()
        await this.getRoles()
        await this.getPermissions()
        await this.getUsers()
    },
    methods: {
        selectPermissionType(evt) {            
            this.permission.fatherId = null
        },
        getAllPermissions(menus) {
            let permissions = [];

            menus.forEach(menu => {
                // Agregar el permiso actual con los 3 atributos deseados
                permissions.push({
                    id: menu.permission,
                    name: menu.title,
                    category: menu.category,
                    icon: menu.icon,
                });

                // Si tiene hijos, recorrer los hijos
                if (menu.children && menu.children.length > 0) {
                    permissions = permissions.concat(this.getAllPermissions(menu.children));
                }

                // Si tiene especiales, recorrer los especiales
                if (menu.specials && menu.specials.length > 0) {
                    permissions = permissions.concat(this.getAllPermissions(menu.specials));
                }
            });

            return permissions;
        },
        getEnabledMenuIds(menus) {
            let enabledMenuIds = [];

            menus.forEach(menu => {                
                if (menu.enabled) {
                    enabledMenuIds.push(menu.permission);
                }
                if (menu.children && menu.children.length > 0) {
                    enabledMenuIds = enabledMenuIds.concat(this.getEnabledMenuIds(menu.children));
                }

                if (menu.specials && menu.specials.length > 0) {
                    enabledMenuIds = enabledMenuIds.concat(this.getEnabledMenuIds(menu.specials));
                }
            });

            return enabledMenuIds;
        },
        _getEnabledMenus(items) {
            const enabledMenus = [];

            const findEnabledMenus = (items) => {
                items.forEach(item => {
                    if (item.enabled) {
                        enabledMenus.push(item); // Agrega el ítem a la lista si está habilitado
                    }
                    // Recorrer hijos y especiales si existen
                    if (item.children) {
                        findEnabledMenus(item.children); // Busca en los hijos de forma recursiva
                    }
                    if (item.specials) {
                        findEnabledMenus(item.specials); // Busca en los especiales de forma recursiva
                    }
                });
            };

            findEnabledMenus(items); // Inicia la búsqueda recursiva
            return enabledMenus; // Devuelve la lista plana
        },
        toggleCollapse(index) {
            if (!this.collapsed[index]) {
                // Solo carga los datos si la sección está colapsada
                this.fetchPermissionsForSection(index);
            }
            this.collapsed[index] = !this.collapsed[index];
        },
        toggleMenu(index) {
            this.$root.$emit('bv::toggle::collapse', 'menu-' + index);
        },
        navigate(route) {
            // Aquí puedes realizar la lógica de navegación a las rutas correspondientes
            this.$router.push({ name: route });
        },
        async getConnections() {  
            try {    
                this.loading = true
                const { data } = await ConnectionsResource.getByStatus(1) //1 = Activa
                this.loading = false
                if (data.isSuccesful) {
                this.connections = data.data
                if (this.connections) {
                    store.dispatch('auth/UPDATE_CONNECTIONS', this.connections)
                }
                } else {
                this.danger(data.message)
                }
            }catch(e) {
                console.log(JSON.stringify(e))
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        handleCheckboxChange(selectedItem) {
            // Deshabilita todas las filas excepto la seleccionada
            this.wareHouses.forEach(item => {
                if (item !== selectedItem) {
                    item.enabled = false;
                }
            });
        },
        async updateBranchPermissions() {
            const payload = {                
                permiso_Id: 0,
                role_Id: "xxxxxxxxx",
                listWebRolesPermisos: [],
                listWebUsuariosPermisos: this.branches.map(item => ({
                    type: item.enabled ? 'added' : 'remove',
                    usuario_Id: this.user_id,
                    permiso_Id: 7,
                    empresa_Id: this.connection_id,
                    sucursal_Id: item.sucursal_Id,
                    sucursal_Nombre: item.nombre,
                }))
            }
            

            try {
                this.loading = true
                const { data } = await PermissionResource.AssingPermissions(payload)
                this.loading = false

                if(data.isSuccesful) {
                    this.success(data.message)
                    await this.getUsers()
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async updateWareHousePermissions() {
     
            const payloads = this.wareHouses.map(item => ({
                Activo: item.enabled,
                usuario_Id: this.formWareHouses.user_id,                    
                empresa_Id: this.formWareHouses.connection_id,
                almacen_Id: item.almacen_Id,
                almacen_Nombre: item.nombre,
            }))

            try {
                this.loading = true
                const { data } = await PermissionResource.AssingUserWarehouse(payloads)
                this.loading = false

                if(data.isSuccesful) {
                    this.success(data.message)
                    await this.getUsers()
                } else {
                    this.danger(data.message)
                }

            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async selectConnectionWarehouses(evt) {
            try {
                this.loading = true
                const { data} = await WareHousesResouce.getByCompanyId(evt)
                this.loading = false

                if (data.isSuccesful) {
                    this.wareHouses = data.data.map(item => ({...item, enabled: false}))

                    //Get user filter
                    const user = this.users.find(item => item.id === this.formWareHouses.user_id)    
                    
                    console.log(['user', user])
                    
                    this.wareHouses.forEach(almacen => {
                        const foundUser = user.almacenes.find(a => a.almacen_Id === almacen.almacen_Id && a.empresa_Id === this.formWareHouses.connection_id);
                        if (foundUser) {
                            almacen.enabled = true;
                        } else {
                            almacen.enabled = false;
                        }
                    });
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        resetForm() {
            this.permission = {
                name: '',
                type: '',
                order: null,
                group: '',
                description: '',
                special: false,
                fatherId: '',
            }
        },
        async onStorePermission() {

            const type = this.permission.type === 'header1' ? 'header' : this.permission.type
            const category = this.permission.type === 'route' ? 'ESPECIAL' : 'MENUS'
            const payload = {
                Padre_Id : this.permission.fatherId,
                Nombre: this.permission.name,
                Grupo: this.permission.group,
                Descripcion: this.permission.description,
                Orden: 1,
                Tipo: type,
                Categoria: category,
                Status: true,
                Ruta: 'home',
                Icono: 'CircleIcon',
            }

            try {
                this.loading = true
                const { data } = await PermissionResource.store(payload)
                this.loading = false
                if(data.isSuccesful) {
                    this.closeModal()
                    this.success(data.message)
                    await this.getPermissions()
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        closeModal() {
            this.isActiveModal = false
        },
        selectUser () {
            this.newBranches = []
            this.branches = []
            this.connection_id = null
        },
        async updateBranches (evt) {
            if (evt.moved) {
                return false
            }

            console.log(evt)

            let _permissions = []
            if (evt.added) {
                _permissions.push({
                    type: 'added',
                    usuario_Id: this.user_id,
                    permiso_Id: 7,
                    empresa_Id: this.connection_id,
                    sucursal_Id: evt.added.element.sucursal_Id,
                    sucursal_Nombre: evt.added.element.nombre,
                })
            } else if (evt.removed) {
                _permissions.push({
                    type: 'removed',
                    usuario_Id: this.user_id,
                    permiso_Id: 7,
                    empresa_Id: this.connection_id,
                    sucursal_Id: evt.removed.element.sucursal_Id,
                    sucursal_Nombre: evt.removed.element.sucursal_Nombre,
                })
            }            

            const payload = {                
                permiso_Id: 0,
                role_Id: "xxxxxxxxx",
                listWebRolesPermisos: [],
                listWebUsuariosPermisos: _permissions,
            }

            try {
                this.loading = true
                const { data } = await PermissionResource.AssingPermissions(payload)
                this.loading = false                
                if(data.isSuccesful) {                    
                    this.success(data.message)
                    await this.getUsers()
                    // setTimeout(async () => {
                    //     this.rolePermissions = []
                    //     this.permissions = []
                    //     this.checkedPermissions = []
                    //     this.role_id = ''     
                    //     await this.getPermissions()
                    // }, 100);                    
                    
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async updateWareHouses(evt) {
            if (evt.moved) {
                return false
            }

            let _permissions = {}
            if (evt.added) {
                _permissions = {
                    type: 'added',
                    usuario_Id: this.formWareHouses.user_id,                    
                    empresa_Id: this.formWareHouses.connection_id,
                    almacen_Id: evt.added.element.almacen_Id,
                    almacen_Nombre: evt.added.element.nombre,
                }
            } else if (evt.removed) {
                _permissions = {
                    type: 'removed',
                    usuario_Id: this.formWareHouses.user_id,                    
                    empresa_Id: this.formWareHouses.connection_id,
                    almacen_Id: evt.removed.element.almacen_Id,
                    almacen_Nombre: evt.removed.element.nombre,
                }
            }

            const payload = {
                ..._permissions,                
            }

            try {

                this.loading = true
                const { data } = await PermissionResource.AssingUserWarehouse(payload)
                this.loading = false

                if (data.isSuccesful) {
                    this.success(data.message)
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        // iniciar, finalizar, agregar, actualizar, ordenar, eliminar, todos obtienen lo mismo
        start: function (evt) {
            
        },
        end: function (evt) {
            // console.log(evt)
            // evt.item // Puede saber el arrastre en sí
            // evt.to    // Puedes conocer la lista de destino del arrastre
            // evt.from  // puede conocer la lista anterior
            // evt.oldIndex  // Puede conocer la posición antes de arrastrar
            // evt.newIndex  // Puede saber la posición después de arrastrar
        },
        move: function (evt, originalEvent) {
            // console.log(evt)
            // console.log(originalEvent) // Posición del mouse
        },
        async selectConnection () {
            this.loading = true
            const { data } = await BranchesResouce.index(this.connection_id)
            this.loading = false
            this.branches = data.data.map(item => ({...item, enabled: false}))
            const user = this.users.find(item => item.id === this.user_id)            
            
            // Suponiendo que tienes los arreglos branches y users.especiales ya definidos
            this.branches.forEach(branch => {
                // Verificar si la sucursal está en users.especiales
                const foundUser = user.especiales.find(u => u.sucursal_Id === branch.sucursal_Id && u.empresa_Id === this.connection_id);
                
                // Si la sucursal está en users.especiales, establecer el atributo enabled en true
                if (foundUser) {
                    branch.enabled = true;
                } else {
                    // Si no se encuentra, establecer el atributo enabled en false (opcional)
                    branch.enabled = false;
                }
            });
                
        },
        async getUsers() {
            this.loading = true
            const { data } = await UsersResource.index()
            this.loading = false

            //Ignorar Administrador
            this.users = data.map(u => ({...u.user, roleName: u.roles[0], especiales: u.especiales, almacenes: u.almacenes}))
                .filter(item => item.id !== store.getters['auth/getUserIdAdmin']).flat()
        },
        async getRoles () {
            this.loading = true
            const { data } = await RolesResource.index()
            this.loading = false           
            this.roles = data.filter(r => r.name !== 'Administrador')
        },
        async getPermissions() {           
            this.loading = true;
            this.permissions = []
            const { data } = await PermissionResource.index();
            this.loading = false;
            this.permissions = data.data.map(p => ({...p, disabled: true, seleted: false })) //_.groupBy(_.orderBy(this.rolePermissions.filter(p => p.categoria === 'MENUS'), ['orden', 'asc']), 'grupo')
            this.menus = this.getAllPermissions(this.permissions)
        },
        async selectRole(e) {
            try {
                const rolId = e;
                this.loading = true;
                const { data } = await PermissionResource.getPermissions(rolId);
                this.loading = false
                console.log(data)

                // this.permissions = this.permissions.map(p => {
                //     p.disabled = true;
                //     return p;
                // }); 

                if (data.isSuccesful) {
                    const newResult = this.enablePermissionsInHierarchy(this.permissions, data.data);    
                    console.log(newResult)
                }

                // console.log(data)
                // console.log(this.permissions)

                

                // this.rolePermissions.forEach(element => {
                //     const matchingPermission = data.data.find(p => p.permiso_Id === element.permiso_Id);
                //     element.enabled = !!matchingPermission;
                //     element.role_id = rolId;
                // });

                // this.permissions = _.groupBy(_.orderBy(this.rolePermissions.filter(p => p.categoria === 'MENUS'), ['orden', 'asc']), 'grupo')
                
            } catch (error) {
                this.handleResponseErrors(e)
            } finally {
                this.loading = false; // Ensure loading is always set back to false
            }
        },

        //Función para habilitar permisos en la jerarquía:
        enablePermissionsInHierarchy(permissions, rolePermissions) {
            return permissions.forEach(permission => {
                // permission.disabled = true; 
                permission.enabled = false;

                // Buscar si el permiso está en la lista de permisos del rol
                const rolePermission = rolePermissions.find(rp => rp.permiso_Id === permission.permission);
                
                // Si lo encuentra, habilitar el permiso
                if (rolePermission) {
                    permission.enabled = true;
                }

                // Si el permiso tiene hijos (children), recorrerlos y habilitarlos si es necesario
                if (permission.children && permission.children.length > 0) {
                    this.enablePermissionsInHierarchy(permission.children, rolePermissions);
                }

                // Si el permiso tiene especiales (specials), recorrerlos y habilitarlos si es necesario
                if (permission.specials && permission.specials.length > 0) {
                    this.enablePermissionsInHierarchy(permission.specials, rolePermissions);
                }
            });
        },
       
        async updatePermissions () {
          
            const payload = {                
                permiso_Id: 0,
                role_Id: this.role_id,
                listWebRolesPermisos: this.checkedPermissions.map(item => ({
                role_Id: this.role_id,
                permiso_Id: item,
            })),
                listWebUsuariosPermisos: []
            }
           
            try {
                this.loading = true
                const { data } = await PermissionResource.AssingPermissions(payload)
                this.loading = false                
                if(data.isSuccesful) {
                    // store.commit('auth/SET_ALL_PERMISSIONS', data.data)
                    this.success(data.message)
                    setTimeout(async () => {
                        this.rolePermissions = []
                        this.permissions = []
                        this.checkedPermissions = []
                        this.role_id = ''     
                        await this.getPermissions()
                    }, 100);                    
                    
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        changePermission(item) {

            // Verificar si el permiso ya está en el arreglo
            const existingPermissionIndex = this.checkedPermissions.findIndex(permission => {
                return permission.role_Id === this.role_id && permission.permiso_Id === item.permiso_Id;
            });

            if (existingPermissionIndex !== -1) {
                // Si el permiso ya está en el arreglo, quítalo (unchecked)
                this.checkedPermissions.splice(existingPermissionIndex, 1);
            } else {
                // Si el permiso no está en el arreglo, agrégalo (checked)
                this.checkedPermissions.push({
                    role_Id: this.role_id,
                    permiso_Id: item.permiso_Id,
                });
            }
        },
        checkPermission (item) {
            if (!this.role_id) return false

            // const role = item.roles.find(r => r.id === this.role_id);
            return false //role && role.pivot ? item.id == role.pivot.permission_id : false;
        },
    },
    watch: {
        permissions: {
            handler: function (val) {
               this.checkedPermissions = this.getEnabledMenuIds(val);
            },
            deep: true
        }
    },
    setup() {

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        return {
            perfectScrollbarSettings,
            navMenuItems,
        }
    }
};
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.list-group-item {
  transition: all 1s
}
</style>